import styled from "styled-components"

import {
  getSensorValue,
  mouldInfo,
  mouldRiskLevelStringify,
  showMouldInfo,
} from "src/data/devices/logic/deviceLogic"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { monitoringAvailable } from "src/data/homes/logic/homeUtil"
import { IHome, MonitoringType } from "src/data/homes/types/homeTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { colorsV2 } from "src/ui/colors"
import HumidityIcon from "src/ui/icons/humidity.svg"
import MouldIcon from "src/ui/icons/mold.svg"
import SoundIcon from "src/ui/icons/noise-normal.svg"
import TemperatureIcon from "src/ui/icons/temperature.svg"
import { MInfo } from "src/ui/Info/MInfo"
import LocalizedTemperature from "src/ui/LocalizedTemperature"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function LatestSensorValues({
  home,
  device,
}: {
  home: IHome
  device: TDevice
}) {
  const { t } = useTranslate()

  const sensorValues = device.latest_sensor_values
  const sensorIsOffline = device.offline ?? false
  const mouldRiskLevel = device.mould_risk_level
  const chargeStatus = device.charge_status

  return (
    <Readings>
      {monitoringAvailable({
        type: MonitoringType.SOUND,
        home,
        devices: [device],
      }) && (
        <Reading>
          <Label>
            <Image>
              <SoundIcon />
            </Image>
            <MText variant="bodyS" color="secondary">
              {t(langKeys.sound)}
            </MText>
          </Label>
          <SensorValueLabel variant="heading2">
            {sensorIsOffline
              ? "-"
              : `${getSensorValue(sensorValues?.sound)} dB`}
          </SensorValueLabel>
        </Reading>
      )}

      {monitoringAvailable({
        type: MonitoringType.TEMPERATURE,
        home,
        devices: [device],
      }) && (
        <Reading>
          <Label>
            <Image>
              <TemperatureIcon />
            </Image>
            <MText variant="bodyS" color="secondary">
              {t(langKeys.temperature)}
            </MText>
          </Label>
          <SensorValueLabel variant="heading2">
            {chargeStatus === "connected_charging" ? (
              <MText variant="bodyS" color="secondary">
                {t(langKeys.sensor_data_charging_placeholder)}
              </MText>
            ) : (
              sensorIsOffline || (
                <LocalizedTemperature
                  value={sensorValues?.temperature?.value}
                />
              )
            )}
          </SensorValueLabel>
        </Reading>
      )}

      {monitoringAvailable({
        type: MonitoringType.HUMIDITY,
        home,
        devices: [device],
      }) && (
        <Reading>
          <Label>
            <Image>
              <HumidityIcon />
            </Image>
            <MText variant="bodyS" color="secondary">
              {t(langKeys.humidity)}
            </MText>
          </Label>
          <SensorValueLabel variant="heading2">
            {chargeStatus === "connected_charging" ? (
              <MText variant="bodyS" color="secondary">
                {t(langKeys.sensor_data_charging_placeholder)}
              </MText>
            ) : sensorIsOffline ? (
              "-"
            ) : (
              `${getSensorValue(sensorValues?.humidity)} %`
            )}
          </SensorValueLabel>
        </Reading>
      )}

      {monitoringAvailable({
        type: MonitoringType.MOLD,
        home,
        devices: [device],
      }) && (
        <Reading>
          <Label>
            <Image>
              <MouldIcon />
            </Image>
            <MText variant="bodyS" color="secondary">
              {t(langKeys.risk_of_mold)}
            </MText>
          </Label>
          <SensorValueLabel variant="heading2">
            {mouldRiskLevelStringify(mouldRiskLevel, t)}
            {showMouldInfo(mouldRiskLevel) && (
              <MInfo content={mouldInfo(mouldRiskLevel, t)} />
            )}
          </SensorValueLabel>
        </Reading>
      )}
    </Readings>
  )
}

const Readings = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Reading = styled.div`
  padding: ${spacing.M} ${spacing.XL};
  border: 1px solid ${colorsV2.divider};
  border-width: 1px 0 1px 1px;
  white-space: nowrap;

  &:first-child {
    border-radius: 0.5rem 0 0 0.5rem;
  }

  &:last-child {
    border-radius: 0 0.5rem 0.5rem 0;
    border-width: 1px;
  }
`

const Label = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  gap: ${spacing.XS};
`

const Image = styled.div`
  width: 24px;
`

const SensorValueLabel = styled(MText)`
  display: flex;
  margin-top: ${spacing.M};
  gap: ${spacing.XS2};
`
