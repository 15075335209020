import { useParams } from "react-router-dom"
import styled from "styled-components"

import Skeleton from "@material-ui/lab/Skeleton"

import { DeviceMonitoringStatus } from "src/components/Devices/DeviceMonitoringStatus"
import { EmptyState } from "src/components/EmptyState/EmptyState"
import { ErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary"
import { DeviceConfigurationView } from "src/components/Homes/DeviceDetails/Configuration/DeviceConfigurationView"
import {
  useFetchDevice,
  usePutDevice,
} from "src/data/devices/queries/deviceQueries"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHome } from "src/data/organizations/queries/homeQueries"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Editable } from "src/ui/Editable/Editable"
import Svg404 from "src/ui/icons/404.svg"
import { MainView } from "src/ui/Layout/MainView"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { TabPanel } from "src/ui/Tabs/TabPanel"
import { Tabs } from "src/ui/Tabs/Tabs"
import { isClientError } from "src/utils/requests"

import { DeviceOverview } from "./Overview/DeviceOverview"
import { DeviceLastUpdated } from "./DeviceLastUpdated"

export function DeviceDetails() {
  const { t } = useTranslate()
  const { org, orgId } = useOrganization()
  const { deviceId } = useParams<{ deviceId: string }>()
  const putDevice = usePutDevice(orgId)
  const hasAdminAccess = getAccessLogic({ role: org.user_role }).hasAdminAccess

  const fetchDevice = useFetchDevice({
    orgId,
    deviceId: deviceId,
    options: {
      retry: (count, err) => {
        const errCode = err?.response?.status
        // Retry 2 additional times on failure, unless a client error is encounted
        if (isClientError(errCode) || count >= 2) {
          return false
        }
        return true
      },
    },
  })

  const device = fetchDevice.data

  const fetchHome = useFetchHome({
    orgId: org.id,
    homeId: device?.home || "",
    options: {
      enabled: !!device?.home,
    },
  })
  const home = fetchHome.data

  async function onTitleSave(description: string) {
    return putDevice.mutate({ id: deviceId, data: { description } })
  }

  const loading = fetchDevice.isLoading

  const showPlaceholder = !device || !home

  if (!loading && !device) {
    return (
      <EmptyState
        title={t(langKeys.failed_general_error_title)}
        body={t(langKeys.failed_general_error_body)}
        icon={<Svg404 />}
      />
    )
  }

  return (
    <ErrorBoundary>
      <MainView
        title={
          loading ? (
            <TitlePlaceholder />
          ) : (
            <Editable onSave={onTitleSave} disabled={!hasAdminAccess}>
              {device?.description}
            </Editable>
          )
        }
        metaTitle={device?.description}
        titleBarProps={{
          description: (
            <Description
              device={device}
              loading={loading}
              timezone={home?.timezone}
            />
          ),
          bottomMargin: "small",
        }}
      >
        <Tabs
          labels={[
            { label: langKeys.overview, id: "overview" },
            { label: langKeys.settings, id: "settings" },
          ]}
        >
          <StyledTabPanel>
            {loading || showPlaceholder ? (
              <OverviewPlaceholder />
            ) : (
              <DeviceOverview device={device} home={home} />
            )}
          </StyledTabPanel>
          <StyledTabPanel>
            {loading || !device ? null : (
              <DeviceConfigurationView devices={[device]} />
            )}
          </StyledTabPanel>
        </Tabs>
      </MainView>
    </ErrorBoundary>
  )
}

function Description({
  device,
  loading,
  timezone,
}: {
  device?: TDevice
  loading: boolean
  timezone?: string
}) {
  if (loading) {
    return <StatusPlaceholder />
  }

  if (!device || !timezone) {
    return null
  }

  return (
    <ErrorBoundary mini>
      <SensorStatus>
        <DeviceMonitoringStatus device={device} />

        <MText variant="bodyS" color="secondary">
          <DeviceLastUpdated
            online={!device?.offline}
            lastHeardFromAt={device?.last_heard_from_at}
            timezone={timezone}
          />
        </MText>
      </SensorStatus>
    </ErrorBoundary>
  )
}

function TitlePlaceholder() {
  return <Skeleton variant="text" height={48} width={300} />
}

function StatusPlaceholder() {
  return <Skeleton variant="text" height={40} width={400} />
}

function OverviewPlaceholder() {
  return (
    <div>
      <Skeleton
        variant="rect"
        height={130}
        width={450}
        style={{ borderRadius: "14px", marginBottom: spacing.XL }}
      />
      <Skeleton
        variant="rect"
        height={400}
        style={{ borderRadius: "14px", marginBottom: spacing.XL }}
      />
      <Skeleton
        variant="rect"
        height={400}
        style={{ borderRadius: "14px", marginBottom: spacing.XL }}
      />
      <Skeleton
        variant="rect"
        height={400}
        style={{ borderRadius: "14px", marginBottom: spacing.XL }}
      />
    </div>
  )
}

const SensorStatus = styled.div`
  display: flex;
  gap: ${spacing.M};
  align-items: center;
`

const StyledTabPanel = styled(TabPanel)`
  margin-top: ${spacing.XL2};
`
